<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { mapActions, mapGetters } from "vuex";

/**
 * Master Log component
 */
export default {
  page: {
    title: "Master Log",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Master Log",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Log",
          active: true,
        },
      ],
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "createdBy", label: "User" },
        { key: "id", label: "ID" },
        { key: "entity", label: "Entity" },
        { key: "action", label: "Action" },
        { key: "createdAt", label: "Dibuat Pada" },
        { key: "compare", label: "Compare Data" },
      ],
      loading: false,
      oldValue: null,
      newValue: null,
      dataCompare: null,
      notAllowed: [
        "password",
        "passwordConfirmation",
        "currentHashedRefreshToken",
      ],
      entities: [
        {
          text: "All",
          value: "",
        },
        {
          text: "Banner",
          value: "Banner",
        },
        {
          text: "Category",
          value: "Category",
        },
        {
          text: "Course",
          value: "Course",
        },
        {
          text: "Module",
          value: "Module",
        },
        {
          text: "User",
          value: "User",
        },
      ],
      actions: [
        {
          text: "All",
          value: "",
        },
        {
          text: "Insert",
          value: "INSERT",
        },
        {
          text: "Update",
          value: "UPDATE",
        },
        {
          text: "Remove",
          value: "REMOVE",
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    user() {
      return this.$store.getters["auth/currentUser"];
    },

    tableData() {
      return this.$store.getters["audit/audits"];
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    search: {
      get() {
        return this.$store.getters["audit/filter"].search;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "search", value: val });
        this.loading = false;
      },
    },

    actionFilter: {
      get() {
        return this.$store.getters["audit/filter"].action;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "action", value: val });
        this.loading = false;
      },
    },

    entityFilter: {
      get() {
        return this.$store.getters["audit/filter"].entity;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "entity", value: val });
        this.loading = false;
      },
    },

    perPage: {
      get() {
        return this.$store.getters["audit/filter"].limit;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "limit", value: val });
        this.loading = false;
      },
    },

    currentPage: {
      get() {
        return this.$store.getters["audit/filter"].page;
      },
      async set(val) {
        this.loading = true;
        await this.changeFilter({ key: "page", value: val });
        this.loading = false;
      },
    },

    ...mapGetters({
      courses: "course/courses",
      users: "user/users",
      statuses: "audit/statuses",
      modules: "module/modules",
      total: "audit/total",
    }),
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.loading = true;
    await this.fetch();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetch: "audit/fetch",
      changeFilter: "audit/changeFilter",
    }),

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    checkMapping(data, field) {
      try {
        if (!data) return "-";

        switch (field) {
          case "createdBy":
            return data[field].firstName;
          case "user":
            return data[field].firstName;
          default:
            return data[field] || "-";
        }
      } catch (error) {
        return "-";
      }
    },

    compareData(data) {
      this.oldValue = data.oldValue;
      this.newValue = data.newValue;
      console.log(data.newValue)
      this.dataCompare = Object.keys(data.newValue).map((key) => {
        if (this.notAllowed.includes(key)) return null;

        const response = {
          key,
          oldValue: this.checkMapping(data.oldValue, key),
          newValue: this.checkMapping(data.newValue, key),
        };
        return response;
      });
    },

    formatText(text) {
      // Define the max number of characters per line
      const maxCharsPerLine = 50; // Adjust as needed

      // Split the text into chunks
      const lines = text
        .toString()
        .match(new RegExp(".{1," + maxCharsPerLine + "}", "g"));

      // Join the chunks with <br> tags
      return lines ? lines.join("<br>") : text.toString();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mb-3"
              dismissible
              @dismissed="$store.dispatch('notification/clear')"
              v-if="notification.message"
              show
              >{{ notification.message }}</b-alert
            >

            <h4 class="card-title">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="row">
                      <!-- <div class="form-group">
                        <label for=""></label>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </h4>
            <div>
              <b-row>
                <b-col>
                  <b-form-group label="Entity">
                    <b-form-select
                      v-model="entityFilter"
                      :options="entities"
                      class="form-control"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Action">
                    <b-form-select
                      v-model="actionFilter"
                      :options="actions"
                      class="form-control"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Tampilkan &nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp; data
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Cari:
                    <b-form-input
                      v-model="search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items.sync="tableData"
                :fields="fields"
                responsive="sm"
                :per-page.sync="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter.sync="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="loading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-3">Loading...</strong>
                  </div>
                </template>

                <template #cell(createdBy)="data">
                  <div v-if="data.item.createdBy">
                    <p style="margin: 0;">
                      {{ data.item.createdBy.firstName }}
                    </p>
                    <p>
                      {{
                        data.item.createdBy.roles
                          .map((role) => role.name)
                          .join(", ")
                      }}
                    </p>
                  </div>
                  <div v-else>-</div>
                </template>

                <template #cell(createdAt)="data">
                  <p v-if="data.item.createdAt">
                    {{
                      data.item.createdAt | moment("DD MMMM YYYY - HH:mm:SS")
                    }}
                  </p>
                  <p v-else>-</p>
                </template>

                <template #cell(compare)="data">
                  <b-button
                    v-b-modal.modal-compare
                    variant="primary"
                    size="sm"
                    @click="compareData(data.item)"
                    >Compare</b-button
                  >
                </template>

                <template #cell(type)="data">
                  {{ data.item.type | titleCase }}
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-modal centered id="modal-compare" title="Compare Data" size="xl">
        <div class="col-12">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Field</th>
                <th>Old Value</th>
                <th>New Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in dataCompare" :key="data.key">
                <td style="width: 20%;">{{ data.key }}</td>
                <td style="width: 40%">
                  <div v-html="formatText(data.oldValue)"></div>
                </td>
                <td style="width: 40%">
                  <div v-html="formatText(data.newValue)"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-modal>
    </div>
  </Layout>
</template>
